<template>
<div>
    <div class="display-block">
        <v-alert v-if="msg" :type="msgType">
            {{msg}}
        </v-alert>
        <page-header v-model="search" title="Package" @input="updateApiCall(apiCall,search)" permission="createPackage" @click="navigateTo('/app/packages/0')"></page-header>
        <v-flex text-right>
        </v-flex>
    </div>
    <div>
        <v-data-table :loading="loading" :headers="headers" :page="currentPage" @update:page="setCurrentPage($event,lsCurrentPageKey)" :items="items" class="elevation-1" :search="search" hide-default-footer :items-per-page="items.length">
            <template v-slot:item.image="{item}" v-if="$vuetify.breakpoint.smAndUp">
                <video-player v-if="item.image&&item.image.mimetype.startsWith('video/')" :src="item.image.path" height="64" width="64"></video-player>
                <v-img v-else-if="item.image&&item.image.mimetype.startsWith('image/')" :src="imagePath(item.image.path)" height="64px" width="64px"></v-img>
            </template>
            <template v-slot:item.action="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <template v-for="icon in icons">
                    <action-button :key="icon.name" :icon="icon.name" :show="isAllowed(icon.permission)" @click="icon.name=='mdi-delete'?deletePackage(item._id):$router.push(icon.path+''+item._id)"></action-button>
                </template>
            </template>
            <template v-slot:item="{item}" v-if="$vuetify.breakpoint.xsOnly">
                <product-card :product="item" :icons="icons" @delete="removeItem"></product-card>
            </template>
            <template v-slot:item.bType="{ item }" v-if="$vuetify.breakpoint.smAndUp">
                <v-chip small>{{item.bType=='ONE_TIME'?'ONE TIME':'FUTURE BUY'}}</v-chip>
            </template>
        </v-data-table>
        <pagination ref="pagination" :api="apiCall" v-model="items" :update="search!=''"></pagination>
    </div>
    <v-alert v-if="msg" :type="msgType">
        {{msg}}
    </v-alert>
</div>
</template>

    
<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductResponsiveCard'
export default {
    components: {
        ActionButton,
        ProductCard
    },
    data() {
        return {
            search: '',
            headers: [{
                    text: 'Image',
                    value: 'image'
                },{
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: 'Selling Price',
                    value: 'sellingPrice'
                },
                {
                    text: 'Buy Type',
                    value: 'bType'
                },
                {
                    text: 'Action',
                    value: 'action',
                    align: 'right'
                }
            ],
            search: '',
            items: [],
            brands: [],
            icons: [{
                    name: "mdi-eye",
                    path: "/app/packages/view/",
                    permission: "viewProduct"
                }, {
                    name: "mdi-pencil",
                    path: "/app/packages/",
                    permission: "editProduct"
                },
                {
                    name: "mdi-delete",
                    permission: "deleteProduct"
                }
            ],
            loading: false,
            apiCall: appConstants.PACKAGES_API
        }
    },
    methods: {
        async deletePackage(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Package?", appConstants.PACKAGES_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
            }
        },
        removeItem(id) {
            this.deletePackage(id)
        },
    }
}
</script>

    
<style scoped>
.circle {
    border-radius: 3px;
}
</style>
